import React from 'react';
import { toUpper } from 'lodash';
import {
  Container, Row, Col, Card, CardText, CardBody,
  CardHeader, CardTitle, CardSubtitle, Badge
} from 'reactstrap';
import {
  Timeline,
  Events,
  TextEvent,
} from '@merc/react-timeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { theme } from './ExperimentPhasesTheme';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'
import { HashLink as Link } from 'react-router-hash-link';
import { useSelector } from 'react-redux'
import { selectors as AuthSelectors } from '../store/slices/auth'


export const ExperimentDetails = (props: any) => {

  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const isLogged = useSelector(AuthSelectors.isLogged);

  const FaseCard = ({ children }: any) => (
    <div>
      <span>{children} </span>
    </div>
  );

  const { content } = props;
  //console.log("esperimento:", content);

  const renderRicercatori = () => {
    return content.ricercatori.map((ricercatore: string, index: number) => {
      return (
        <>
          <span key={ricercatore} style={{ margin: '2px', color: "#007bff" }}><small>{ricercatore}</small></span>
          <br></br>
        </>
      )
    })
  };

  

  const renderGradi = () => {
    return content.gradi.map((grado: string, index: number) => {
      return (
        <>
          <span key={grado} style={{ margin: '2px', color: "#007bff" }}><small>{grado}</small></span>
          <br></br>
        </>
      )
    })
  };

  const renderTagsAsBadges = () => {
    return content.tags.map((tag: string, index: number) => { return (<Badge key={tag} style={{ margin: '2px' }} color="success"> {tag}{'    '}</Badge>) })
  };

  const renderTags = () => {
    return content.tags.map((tag: string, index: number) => {
      return (
        <small>
          <span key={tag} style={{ margin: '2px', color: "#007bff" }}>{tag}{index < (content.tags.length - 1) && ","}</span>
        </small>
      )

    })
  };

  const renderFasi = () => {
    return content.fasi.map((fase: string, index: number) => {
      return (<TextEvent key={index} date={""} text={fase} card={FaseCard} />)
    })
  };

  const renderAvailableLanguages = () => {
    return (
      <div style={{ marginBottom: "5px", display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: `${isLogged ? "timelines" : "calendar"}` }} >
            {renderTimelineFlags()}
          </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: "interactive_sessions" }} >
            {renderInteractiveSessions()}
          </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: "calendar" }} >
            {renderLiveSessionsFlags()}
          </Link>
        </div>


      </div>)
  }

  const renderTimelineFlags = () => {

    const timelines = content.timeline_pubbliche;
    if (timelines == null || timelines.length < 1) return null;
    const languages = new Set()
    for (let i = 0; i < timelines; i++) {
      if (timelines[i]["languages"] != null)
        languages.add(timelines[i]["languages"])
      else languages.add("IT");
    }
    if (languages.size < 1) { languages.add("IT"); }
    //console.log("LANGUAGES:::", languages)

    return (
      <div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <u>{"Timeline"}</u>
        {Array.from(languages).map((language: any, index: any) => {
          return <Flag key={index} code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />
        })}
      </div>
    )
  }

  const renderInteractiveSessions = () => {
    //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    if (!content.interactive_devices || !content.interactive_devices[0]) return null;
    return (
      <div style={{ color:"white", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <u>{t(`Sessioni pratiche`)}</u>
      </div>
    )
  }

  const renderLiveSessionsFlags = () => {
    //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    if (!content.live) return null;
    const countries = content.languages || ["IT"];
    return (
      <div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <u> {t(`Sessioni sincrone`)}</u>
        {countries.map((language: string) => {
          return <>
            <Flag code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />
          </>
        })}
      </div>
    )
  }

  const renderInfo = () => {
    return (<Container style={{ marginBottom: "10px" }}>
      <Row>
        <CardText>
          <b>{t("Periodo di erogazione")}</b>{": "}{`${content.periodo_erogazione[0]} - ${content.periodo_erogazione[1]}`}
        </CardText>
      </Row>
      <Row>
        <CardText>
          <b>{t("Durata")}</b>{": "}{`${content.durata} ${t("minuti")}`}
        </CardText>
      </Row>
      <Row>
        <CardText>
          <b>{t("Frequenza")}</b>{": "}{`${content.frequenza_mensile}`}
        </CardText>
      </Row>


    </Container>
    )
  }

  const renderSchedaEsperimento = () => {
    return content.url_scheda != null &&
      <CardText><a target="_blank" href={content.url_scheda}>
        Scarica la scheda completa dell'esperimento</a>
      </CardText>
  }

  return (<Container fluid>
    <Row>
      <Col xs="12">
        <Card className="mb-4" style={{
          borderColor: "#007bff"
        }}>
          <CardHeader style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            paddingBottom: 0,
            color: 'white'

          }}>
            <div >
              <CardTitle tag="h5">{content.titolo} <small><br></br>{content.laboratorio}</small>

              </CardTitle>
              <div>
                {renderAvailableLanguages()}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs="6">
                <CardSubtitle ><b>{t("descrizione")}</b></CardSubtitle>
                <CardText dangerouslySetInnerHTML={{ __html: content.descrizione }} />
                {renderSchedaEsperimento()}


                <CardSubtitle style={{ marginTop: "10px" }}><b>{t("Opportunità culturale del Laboratorio")}</b></CardSubtitle>
                <CardText>{content.opportunita_culturale}</CardText>
                {renderInfo()}
              </Col>

              <Col sx="6">
                <Row>
                  <Col sx="12">

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "5px", paddingBottom: "5px", border: '2px solid #000' }}>
                      <CardSubtitle style={{ margin: "10px", textAlign: "center" }} ><b>{t("Fasi dell'esperimento")}</b></CardSubtitle>
                      <Timeline theme={theme}>
                        <Events>
                          {renderFasi()}
                        </Events>
                      </Timeline>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardSubtitle style={{ marginTop: "10px" }}><b>{t("Ricercatori")}</b></CardSubtitle>
                    {renderRicercatori()}
                  </Col>

                </Row>

                <Row>
                  <Col>
                    <CardSubtitle style={{ marginTop: "15px" }}><b>{t("Destinatari")}</b></CardSubtitle>
                    {renderGradi()}

                    <Row>
                      <Col>
                        <CardSubtitle style={{ marginTop: "15px" }}><b>{t("Parole chiave")}</b></CardSubtitle>
                        {renderTags()}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <span>&nbsp;</span>
            </Row>
            {/* 
        <Row  style={{display: 'flex', justifyContent:'flex-end'}}>
        <div>
            <a target="_blank" href={`/timeline/f7ab5ebb-946e-4257-ad5f-fb4a2dfcc538`}>Vai alla Timeline principale dell'esperimento <FontAwesomeIcon icon={faChevronCircleRight} style={{ fontSize: 18 }} color="#007bff" /></a>
          </div>
         
        </Row>
        */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>)
}