import { call, put, takeLatest } from 'redux-saga/effects'
import { select } from 'redux-saga/effects';
import { selectors as AppointmentSelectors, actions as AppointmentActions } from '../slices/appointments'
import { actions as UiActions } from '../slices/ui'
import {
    createRemoteSession, updateRemoteSession, deleteRemoteSession,
    listRemoteSessions, listBookedSessions, listRemoteSessionsByTitle,
    listBookingsByRemoteSession,
    listDirectors, getSessionDirectors, updateSessionDirectors,
    listInteractiveDevices,
    putGrantedAttendeesInRemoteSession,
    putHomeworkUrlInRemoteSession,
    getResourcesForSession, bookRemoteSession, unbookRemoteSession, 
    unbookRemoteSessionByEditor,
    bookInteractiveSession, unbookInteractiveSession,
    startStopEC2byInteractiveDeviceId
}
    from '../../api/sessions'
import { actions as UIActions } from '../slices/ui'
import moment from 'moment'

import { syncCalendars } from '../../api/directors';
import { CI } from 'country-flag-icons/react/3x2';

export const INTERACTIVE_SESSION_TYPE = "interactive_session"
export const SYNCHRONOUS_SESSION_TYPE = "sync_session"

export function* sagas() {
    yield takeLatest(AppointmentActions.willCreateAppointment.type, willCreateAppointment);
    yield takeLatest(AppointmentActions.willUpdateAppointment.type, willUpdateAppointment);
    yield takeLatest(AppointmentActions.willLoadAppointments.type, willLoadAppointments);
    yield takeLatest(AppointmentActions.willLoadAppointmentsAndDirectors.type, willLoadAppointmentsAndDirectors);
    
    yield takeLatest(AppointmentActions.willDeleteAppointment.type, willDeleteAppointment);
    yield takeLatest(AppointmentActions.willBookAppointment.type, willBookAppointment);
    yield takeLatest(AppointmentActions.willUnbookAppointment.type, willUnbookAppointment);
    yield takeLatest(AppointmentActions.willUnbookAppointmentByEditor.type, willUnbookAppointmentByEditor);
    yield takeLatest(AppointmentActions.willLoadResourcesForSession.type, willLoadResourcesForSession);
    yield takeLatest(AppointmentActions.willLoadDirectors.type, willLoadDirectors);
    yield takeLatest(AppointmentActions.willLoadInteractiveDevices.type, willLoadInteractiveDevices);
    yield takeLatest(AppointmentActions.willGetSessionDirectors.type, willGetSessionDirectors);
    yield takeLatest(AppointmentActions.willUpdateSessionDirectors.type, willUpdateSessionDirectors);
    yield takeLatest(AppointmentActions.willPutGrantedAttendeesInRemoteSession.type, willPutGrantedAttendeesInRemoteSession);
    yield takeLatest(AppointmentActions.willPutHomeworkUrlInRemoteSession.type, willPutHomeworkUrlInRemoteSession);
    yield takeLatest(AppointmentActions.willSyncCalendars.type, willSyncCalendars);
    yield takeLatest(AppointmentActions.willStartStopEC2byInteractiveDeviceId.type, willStartStopEC2byInteractiveDeviceId);
}

function* willStartStopEC2byInteractiveDeviceId(action: any): any {
    //console.log("willStartStopEC2byInteractiveDeviceId on", action);
    try {
        const result = yield call(startStopEC2byInteractiveDeviceId,
            action.payload.deviceId,
            action.payload.action);
        //console.log("SAGA willStartStopEC2byInteractiveDeviceId   result:->", result);
    } catch (error) {
        //console.log("SAGA willStartStopEC2byInteractiveDeviceId  fails error ", error)
    }

    yield put(UIActions.stopActivityRunning("updateSessionDirectors"));
}


function* willSyncCalendars(action: any): any {
    //console.log("SyncCalendars SAGA: willSyncCalendars on:", action);
    try {
        const result = yield call(syncCalendars);
        //console.log("SyncCalendars SAGA graphql: willSyncCalendars result:->", result);
    } catch (error) {
        //console.log("SyncCalendars SAGA:willSyncCalendars fails error ", error)
    }

    yield put(UIActions.stopActivityRunning("updateSessionDirectors"));
}


function* willGetSessionDirectors(action: any): any {
    ////console.log("SAGA: willGetSessionDirectors on:", action);
    try {
        const session = action.payload;
        const result = yield call(getSessionDirectors, session);
        ////console.log("SAGA graphql: willGetSessionDirectors result:->", result);
        const payload = { "session": session, "directors": result }
        yield put(AppointmentActions.setSessionDirectors(payload));
    } catch (error) {
        //console.log("SAGA:willGetSessionDirectors fails error ", error)
        //const msg = "Error in willGetSessionDirectors:" + error["errors"][0].message;
    }
}

function* willUpdateSessionDirectors(action: any): any {
    //console.log("SAGA: willUpdateSessionDirectors on:", action);
    try {
        yield put(UIActions.startActivityRunning("updateSessionDirectors"));
        const { session, directors } = action.payload;
        //console.log("SAGA graphql: willUpdateSessionDirectors payload:->", action.payload);
        const result = yield call(updateSessionDirectors, session, directors);
        //console.log("SAGA graphql: willUpdateSessionDirectors result:->", result);
        const payload = { "session": session, "directors": directors }
        yield put(AppointmentActions.setSessionDirectors(payload));
    } catch (error) {
        //console.log("SAGA:willUpdateSessionDirectors fails error ", error)
    }

    yield put(UIActions.stopActivityRunning("updateSessionDirectors"));
}

function* willLoadDirectors(action: any): any {
    //console.log("SAGA: willLoadDirectors");

    try {
        const result = yield call(listDirectors);

        //console.log("SAGA graphql:  listDirectors result:->", result);
        yield put(AppointmentActions.setDirectors(result));
    } catch (error) {
        //console.log("SAGA:willLoadDirectors fails error ", error)
        yield put(AppointmentActions.setDirectors([]));
        const msg = "Error in willLoadDirectors:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }
}

function* willLoadInteractiveDevices(action: any): any {
    //console.log("SAGA: willLoadInteractiveDevices");

    try {
        const result = yield call(listInteractiveDevices);

        console.log("SAGA graphql: listInteractiveDevicesresult:->", result);
        const interactiveDirectorsDict = {} as any;
        result.forEach((element: any) => {
            interactiveDirectorsDict[element["interactiveDevice"]] = element;
        });
        yield put(AppointmentActions.setInteractiveDevices(interactiveDirectorsDict));
    } catch (error) {
        //console.log("SAGA: willLoadInteractiveDevices fails error ", error)
        yield put(AppointmentActions.setDirectors([]));
        const msg = "Error in willLoadInteractiveDevices:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }
}


function* willCreateAppointment(action: any): any {
    //console.log("SAGA: willCreateAppointment", action.payload);

    const appointment = action.payload;

    //console.log("SAGA graphql:Salvo nel db");
    try {

        yield put(UIActions.startActivityRunning("createAppointment"));
        const result = yield call(createRemoteSession, appointment.lab,
            appointment.note,
            moment(appointment.startDate).toISOString(),
            appointment.title,
            moment(appointment.stopDate).toISOString(),
            appointment.language,
            appointment.type,
            appointment.maxBookings);
        /* PERCHE' QUESTO GIRO??
        const newAppId = result["data"]["createRemoteSession"]["id"]
        //console.log("SAGA graphql:  new appointment with id:", newAppId);
        //console.log("SAGA updating redux");
        appointment.id = newAppId;
        appointment.organization = result["data"]["createRemoteSession"]["organization"];
        appointment.meetingId = result["data"]["createRemoteSession"]["meetingId"];
        yield put(AppointmentActions.editAppointment(appointment));
        */
        yield put(AppointmentActions.editAppointment(result["data"]["createRemoteSession"]));
        
        //console.log("SAGA closing modal");
        yield put(UiActions.closeAppointmentEditor({ "type": appointment.type || SYNCHRONOUS_SESSION_TYPE }));

    } catch (error) {
        //console.log("SAGA: willCreateAppointment fails error ", error)
        const msg = "Error in willCreateAppointment:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }

    yield put(UIActions.stopActivityRunning("createAppointment"));
}


function* willLoadAppointments(action: any): any {
    const intervalRange_and_type = action.payload;

    //console.log("SAGA: willLoadAppointments", action.payload);
   
    
    try {
        //console.log("SAGA Appuntamenti da listRemoteSessions: CALLING");
        yield put(AppointmentActions.setAppointmentsLoaded(false));
        const appointments = yield call(listRemoteSessions,
            moment(intervalRange_and_type.start).toISOString(),
            moment(intervalRange_and_type.end).toISOString());
        //console.log("SAGA Appuntamenti da listRemoteSessions:", appointments);

        
        const cAppointmentsAndISessions = yield getConvertedAppointments(appointments)
        //console.log("SAGA graphql: listRemoteSessions calling didAppointmentsloaded with ", cAppointmentsAndISessions);

        yield put(AppointmentActions.didInteractiveSessionsloaded(cAppointmentsAndISessions[INTERACTIVE_SESSION_TYPE]));
        yield put(AppointmentActions.didAppointmentsloaded(cAppointmentsAndISessions[SYNCHRONOUS_SESSION_TYPE]));

    } catch (error) {
        //console.log("SAGA: willLoadAppointments fails error ", error)
    }
}



const sessionDirectorsGenerator = function* (sessions: any): any {
    const sessionDirectors: any = {}
    for (var session of sessions) {
        const result = yield call(getSessionDirectors, session["id"]);
        ////console.log("SAGA: sessionDirectorsGenerator directors result:",result);
        sessionDirectors[session["id"] as any] = result;
    }
    yield put(AppointmentActions.setupAllSessionDirectors(sessionDirectors));
}



function* willLoadAppointmentsAndDirectors(action: any): any {
    yield put(AppointmentActions.setLoadingDirectorDashboard(true));
    //console.log("SAGA: willLoadAppointmentsAndDirectors")
    yield willLoadAppointments(action);
    const appointments = yield select(AppointmentSelectors.getAppointments);
    //console.log("SAGA: willLoadAppointmentsAndDirectors Appointments:", appointments);
    yield sessionDirectorsGenerator(appointments);
    yield put(AppointmentActions.setLoadingDirectorDashboard(false));
}



function* willLoadResourcesForSession(action: any): any {
    const session = action.payload;

    //console.log("SAGA: willLoadResourcesForSession", action.payload);
    try {
        const resources = yield call(getResourcesForSession,
            session
        );

        //console.log("SAGA graphql: getResourcesForSession with ", resources);
        const payload = { "id": session, "items": resources }
        yield put(AppointmentActions.didSessionResourcesLoaded(payload));

    } catch (error) {
        //console.log("SAGA: willLoadResourcesForSession fails error ", error)
    }
}

function* getConvertedAppointments (appointments: Array<any>): any {
    let cappointments = [] as any;
    let cInteractiveSessions = [] as any;
   
    for (let i = 0; i < appointments.length; i++) {
       //DEBUG
        if (false)
            {
                const appBookings = yield call(listBookingsByRemoteSession, appointments[i].id);
                    {
                        console.log("FOUND BOOKINGS:", appBookings);
                    }
            }
       
            
        let event = { ...appointments[i] }

        event.startDate = moment(event.startDate).toDate();
        event.stopDate = moment(event.stopDate).toDate();

        if (
            // è di tipo INTERACTIVE_SESSION 
            event.type?.startsWith(INTERACTIVE_SESSION_TYPE)) {

            cInteractiveSessions.push(event as any);
        }
        else {
            cappointments.push(event as any);
        }

    }
    return { [SYNCHRONOUS_SESSION_TYPE]: cappointments, [INTERACTIVE_SESSION_TYPE]: cInteractiveSessions };
}


function* willUpdateAppointment(action: any): any {
    const appointment = action.payload;
    console.log("SAGA: willUpdateAppointment", action.payload);
    try {

        yield put(UIActions.startActivityRunning("updateAppointment"));
        const result = yield call(updateRemoteSession,
            appointment.id,
            appointment.lab,
            appointment.note,
            moment(appointment.startDate).toISOString(),
            appointment.title,
            moment(appointment.stopDate).toISOString(),
            appointment.language,
            appointment.maxBookings);

        //console.log("SAGA graphql:  updated appointment with id:", appointment.id);
        appointment.organization = result["data"]["updateRemoteSession"]["organization"];
        //console.log("SAGA updating redux willUpdateAppointment res:", result);
        //@audit-fix IMPOSTO L'APPOINTMENT SALVATO!!!
        //yield put(AppointmentActions.editAppointment(appointment)); 
        yield put(AppointmentActions.editAppointment(result["data"]["updateRemoteSession"]));
        //console.log("SAGA closing modal");
        yield put(UiActions.closeAppointmentEditor({ "type": appointment.type || SYNCHRONOUS_SESSION_TYPE }));

    } catch (error) {

        //console.log("SAGA: willUpdateAppointment fails error ", error)
        const msg = "Error in willUpdateAppointment:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }

    yield put(UIActions.stopActivityRunning("updateAppointment"));
}

function* willDeleteAppointment(action: any): any {
    const appointment = action.payload;
    //console.log("SAGA: willDeleteAppointment", action.payload);
    try {

        yield put(UIActions.startActivityRunning("deleteAppointment"));
        const result = yield call(deleteRemoteSession,
            appointment.id,
        );

        //console.log("SAGA: deleteRemoteSession result: ", result)
        //console.log("SAGA graphql:  delete appointment with id:", appointment.id);

        yield put(AppointmentActions.deleteAppointment(appointment));
        //console.log("SAGA closing modal");
        yield put(UiActions.closeAppointmentEditor({ "type": appointment.type || SYNCHRONOUS_SESSION_TYPE }));

    } catch (error) {
        //console.log("SAGA: willDeleteAppointment fails error ", error)
        const msg = "Error in willDeleteAppointment:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }

    yield put(UIActions.stopActivityRunning("deleteAppointment"));
}

function* willBookAppointment(action: any): any {
    const appointment = action.payload;
    const isInteractiveSession = appointment.type?.startsWith(INTERACTIVE_SESSION_TYPE);

    //console.log("SAGA: willBookAppointment Organization:", appointment);
    try {
        yield put(UIActions.startActivityRunning("bookAppointment"));
        const result = yield call(isInteractiveSession ? bookInteractiveSession : bookRemoteSession,
            appointment.organization,
            appointment.id,
            appointment.school,
            appointment.schoolGrade,
            appointment.schoolLevel,
            appointment.schoolSection,
            appointment.country,
            appointment.minAge,
            appointment.maxAge
        );

        const bookedAppointment = result["data"][isInteractiveSession ? "bookInteractiveSession" :
            "bookRemoteSession"];

        //console.log("SAGA graphql:  book appointment result:", bookedAppointment);
        const cappointment = { ...bookedAppointment }
        cappointment.startDate = moment(cappointment.startDate).toDate();
        cappointment.stopDate = moment(cappointment.stopDate).toDate();
        yield put(AppointmentActions.editAppointment(cappointment));
        // alla fine delle operazioni ricarico gli esperimenti
        //console.log("RS: ricarico gli esperimenti")
        yield put(AppointmentActions.willLoadAppointments(
            {
              "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z",
              "type": cappointment.type || ""
            }));
        yield put(UIActions.stopActivityRunning("bookAppointment"));
        return result;
    } catch (error) {
        console.log("SAGA: willBookAppointment fails error ", error)
        const errors = (error as any)["errors"]
        const reason = (errors && errors[0]) ? errors[0].message : "See logs";
        const msg = "Error in willBookAppointment:"  + reason;
        yield put(UiActions.openBackendErrorAlertModal(msg));
        yield put(UIActions.stopActivityRunning("bookAppointment"));
    }

}


function* willUnbookAppointment(action: any): any {
    const appointment = action.payload;
    const isInteractiveSession = appointment.type?.startsWith(INTERACTIVE_SESSION_TYPE);
    //console.log("SAGA: willUnbookAppointment", action.payload);
    try {
        yield put(UIActions.startActivityRunning("unbookAppointment"));

        const result = yield call(isInteractiveSession ? unbookInteractiveSession : unbookRemoteSession,
            appointment.organization,
            appointment.id
        );
        //console.log(`Result of unbookRemoteSession query on appointment ID:${appointment.id} and ORGANIZATION:${appointment.organization}`, result);
        const unbookedAppointment = result["data"][isInteractiveSession ? "unbookInteractiveSession" :
            "unbookRemoteSession"];

        //console.log("SAGA graphql:  unbooked appointment:", unbookedAppointment);
        const cappointment = { ...unbookedAppointment }
        cappointment.startDate = moment(cappointment.startDate).toDate();
        cappointment.stopDate = moment(cappointment.stopDate).toDate();
        yield put(AppointmentActions.editAppointment(cappointment));
        yield put(AppointmentActions.willLoadAppointments(
            {
              "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z",
              "type": cappointment.type || ""
            }));

    } catch (error) {

        //console.log("SAGA: willUnbookAppointment fails error ", error)
        const msg = "Error in willUnBookAppointment:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }
    yield put(UIActions.stopActivityRunning("unbookAppointment"));
}

function* willUnbookAppointmentByEditor(action: any): any {
    const payload = action.payload;
    //const isInteractiveSession = appointment.type?.startsWith(INTERACTIVE_SESSION_TYPE);
    //console.log("SAGA: willUnbookAppointmentByEditor", action.payload);
    try {
        yield put(UIActions.startActivityRunning("unbookAppointment"));
        const result = yield call(unbookRemoteSessionByEditor,
            payload.appointment.organization,
            payload.appointment.id,
            payload.attendeeId
        );
        //console.log(`Result of unbookRemoteSession query on appointment ID:${appointment.id} and ORGANIZATION:${appointment.organization}`, result);
        const unbookedAppointment = result["data"]["unbookRemoteSession"];
        //console.log("SAGA graphql:  unbooked appointment:", unbookedAppointment);
        const cappointment = { ...unbookedAppointment }
        cappointment.startDate = moment(cappointment.startDate).toDate();
        cappointment.stopDate = moment(cappointment.stopDate).toDate();
        yield put(AppointmentActions.editAppointment(cappointment));
        yield put(AppointmentActions.willLoadAppointments(
            {
              "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z",
              "type": cappointment.type || ""
            }));

    } catch (error) {

        //console.log("SAGA: willUnbookAppointment fails error ", error)
        const msg = "Error in willUnBookAppointment:" + (error as any)["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
    }
    yield put(UIActions.stopActivityRunning("unbookAppointment"));
}

function* willPutGrantedAttendeesInRemoteSession(action: any): any {
    const appointment = action.payload;
    const payload = {
        "organization": appointment.organization,
        "id": appointment.id, "reservedTo": appointment.reservedTo, "reservedToSardinianTeachers": appointment.reservedToSardinianTeachers
    }
    //console.log("SAGA: willPutGrantedAttendeesInRemoteSession:::", payload);
    try {
        yield put(UIActions.startActivityRunning("putGrantedAttendeesInRemoteSession"));


        const result = yield call(putGrantedAttendeesInRemoteSession,
            payload.organization,
            payload.id,
            payload.reservedTo,
            payload.reservedToSardinianTeachers
        );
        //console.log(`Result of putGrantedAttendeesInRemoteSession on appointment ID:${appointment.id} and ORGANIZATION:${appointment.organization}`, result);
        const cappointment = { ...appointment }
        cappointment["reservedTo"] = payload.reservedTo;
        cappointment["reservedToSardinianTeachers"] = payload.reservedToSardinianTeachers;
        yield put(AppointmentActions.editAppointment(cappointment));
    } catch (error) {
        console.error("SAGA: Result of putGrantedAttendeesInRemoteSession fails error ", error)
    }
    yield put(UIActions.stopActivityRunning("putGrantedAttendeesInRemoteSession"));
}

function* willPutHomeworkUrlInRemoteSession(action: any): any {
    const appointment = action.payload;
    const payload = {
        "organization": appointment.organization,
        "id": appointment.id, "reservedTo": appointment.reservedTo, 
        "homeworkUrl": appointment.homeworkUrl
    }
    //console.log("SAGA: willPutHomeworkUrlInRemoteSession:::", payload);
    try {
        yield put(UIActions.startActivityRunning("HomeworkUrlInRemoteSession(action"));


        const result = yield call(putHomeworkUrlInRemoteSession,
            payload.organization,
            payload.id,
            payload.homeworkUrl
        );
        //console.log(`Result of putHomeworkUrlInRemoteSession on appointment ID:${appointment.id} and ORGANIZATION:${appointment.organization}`, result);
        const cappointment = { ...appointment }
        cappointment["homeworkUrl"] = payload.homeworkUrl;
        yield put(AppointmentActions.editAppointment(cappointment));
    } catch (error) {
        console.error("SAGA: Result of putHomeworkUrlInRemoteSession fails error ", error)
    }
    yield put(UIActions.stopActivityRunning("putHomeworkUrlInRemoteSession"));
}
